export const socialsData = {
    github: 'https://github.com/naimnarzikulov',
    facebook: 'https://www.facebook.com/naimnarzikulov',
    linkedIn: 'https://www.linkedin.com/in/naim-narzikulov-335842326?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    instagram: 'https://www.instagram.com/naimnarzikulov/',
    codepen: '',
    twitter: '',
    reddit: '',
    blogger: '',
    medium: '',
    stackOverflow: '',
    gitlab: '',
    youtube: 'https://www.youtube.com/@n_AI_m'
}